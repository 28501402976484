<template>
  <HpModal id="what-is-healthpilot" size="md" no-padding>
    <section class="mx-auto mb-3 grid gap-4">
      <header class="mb-4 grid max-w-sm gap-4 place-self-center">
        <HpIcon name="healthpilot-icon" class="mx-auto w-10" />
        <h1 class="text-3xl" v-html="content?.data.heading"></h1>
        <p v-html="content?.data.subheading" class="leading-normal"></p>
      </header>

      <p
        v-for="(paragraph, idx) in content?.data?.content"
        :key="idx"
        v-html="paragraph"
        class="text-left"
      ></p>

      <HpAnchor
        target="_blank"
        :href="content?.data?.cta_link"
        class="link-btn-primary mt-4 flex w-full items-center justify-center pb-3 leading-tight"
        >{{ content?.data?.cta_label }}</HpAnchor
      >
    </section>
  </HpModal>
</template>

<script lang="ts" setup>
  const { getComponentContent } = Cms.use()
  const { content } = await getComponentContent('WhatIsHealthpilot')
</script>

<style scoped></style>
