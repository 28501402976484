<template>
  <HpAnchor
    :href="`tel:${phoneNumber ?? defaultNumber}`"
    class="block whitespace-nowrap text-center leading-none text-gray-900 no-underline"
    :class="{ 'text-white': monochrome }"
  >
    <span v-if="!hidden" class="block text-lg leading-snug">
      <span class="mr-1 font-semibold text-primary" :class="{ 'text-white': monochrome }">
        {{ DisplayPhoneNumber(String(phoneNumber ?? defaultNumber)) }}
      </span>
      | TTY 711
    </span>
    <span
      v-if="isAep && !hidden"
      class="whitespace-nowrap text-xs text-gray-800 lg:text-sm"
      :class="{ 'text-white': monochrome }"
    >
      {{ hours }}
    </span>
    <span
      v-if="!isAep && !hidden"
      class="text-sm text-gray-800"
      :class="{ 'text-white': monochrome }"
    >
      {{ hours }}
    </span>
  </HpAnchor>
</template>

<script lang="ts" setup>
  import DisplaySupportHours from '~/composables/DisplaySupportHours'
  import { SupportNumbers } from '~/composables/SupportNumbers'

  const props = defineProps<{
    monochrome: {
      type: Boolean
    }
    isFooter: {
      type: Boolean
    }
  }>()

  const { hours } = await DisplaySupportHours.use()

  const { flag } = FeatureFlags.use()
  const isAep = computed(() => flag<boolean>('is-aep').value)

  const { phoneNumber, defaultNumber } = await SupportNumbers.use()

  const hidden = computed(() => {
    if (props.isFooter) {
      return false
    }
    return _isNil(phoneNumber.value)
  })
</script>

<style scoped></style>
